import * as Cookies from 'js-cookie'

const prefixUrl = process.env.SERVICE_URL!
const upgradePrefixUrl = process.env.UPGRADE_SERVICE_URL!

export function getFullUrl(url: string) {
  if (!/^(\/|https?)/.test(url)) {
    return prefixUrl + '/' + url
  }
  return url
}

export function setUpgradeToken(val: string) {
  // XXX: 只处理了当前域名下的情况
  return Cookies.set('token', val, { path: upgradePrefixUrl })
}

export function setFakeToken(val: string) {
  localStorage.setItem('token', val)
  // XXX: 只处理了当前域名下的情况
  return Cookies.set('token', val, {
    path: '/',
    sameSite: 'None',
    Secure: true,
  })
}


export function getUpgradeFullUrl(url: string) {
  if (!/^(\/|https?)/.test(url)) {
    return upgradePrefixUrl + '/' + url
  }
  return url
}
