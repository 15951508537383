import './login.scss'
import { eMsg, simpleLoading } from '@/utils/interaction'
import { setCourtList, setValidateImg, handleLogin } from './login'
import { getPhone, sendCode, forgetPassword, checkPassword } from '@/services/login'
import bgPng from './img/bg.png'
import titlePng from './img/lg_title.png'
import imagePng from './img/lg_image.png'
import usernamePng from './img/lg_username.png'
import passwordPng from './img/lg_password.png'
import validatePng from './img/validate.png'

$(function () {
  var InterValObj: any = null     //timer变量，控制时间
  var curCount:any;    //当前剩余秒数
  $('#img_bg').attr('src', bgPng)
  $('#img_lg_title').attr('src', titlePng)
  $('#img_lg_image').attr('src', imagePng)
  $('#img_lg_username').attr('src', usernamePng)
  $('#img_lg_password').attr('src', passwordPng)
  $('#img_validate').attr('src', validatePng)

  const isCourt = $('#loginMode').val() === 'court'
  let step:Number = 1
  let usernameLocal:any
  let phone:any = ''
  let verificationCode: any

  if (isCourt) {
    setCourtList()
  }

  $('#validateImg').click(setValidateImg)
  setValidateImg()

  layui.form.render(null, 'login-form')


  function templateRender(data: any) {
    const tpl = document.getElementById('forgetTpl')
    if (tpl) {
      return layui.laytpl(tpl.innerHTML).render(data)
    }
  }

  function maskPhoneNumber(phoneNumber: string, start = 3, end = 2) {
    if (!phoneNumber || phoneNumber.length === 0) return '';
    let maskedPhoneNumber = phoneNumber.slice(0, start) + '****' + phoneNumber.slice(-end);
    return maskedPhoneNumber;
  }

  layui.form.on('submit(login-submit-form)', (data: any) => {
    const loginData = {
      ...data.field,
      password: data.field.userPwd,
      userPwd: undefined
    }
    handleLogin(isCourt, loginData)
  })
  $('#login-form input').keydown((ev) => {
    if (ev.which === 13) {
      $('#loginBtn').click()
    }
  })
  
  $('#forget').click(() => {
    const layerIndex1 = layer.open({
      type: 1,
      title: '忘记密码',
      area: ['700px', '340px'],
      content: templateRender({
        step: 1,
      }),
      success() {
        layui.form.render(null, 'forget-form')
        layui.form.on('submit(forget-next-button1)', (data: any) => {
          const { userName } = data.field
          simpleLoading.show()
          getPhone(userName).then((res: any) => {
            phone = res
            usernameLocal = userName
            step = 2
            layer.close(layerIndex1)
            simpleLoading.hide()
            const layerIndex2 = layer.open({
              type: 1,
              title: '忘记密码',
              area: ['700px', '340px'],
              content: templateRender({
                step: 2,
                desensitizationPhone: phone
              }),
              success() {
                $('#getCaptcha').click(function(){
                  if (!curCount || curCount === 0) {
                    simpleLoading.show()
                    sendCode(userName).then(() => {
                      layer.msg('验证码已发送');
                      countdown()
                    }).catch((err) => {
                      eMsg(err.message)
                      simpleLoading.hide()
                    })
                  }
              
                  function SetRemainTime() {
                    if (curCount === 0) {  //超时重新获取验证码                
                      window.clearInterval(InterValObj);// 停止计时器
                      $("#getCaptcha").removeClass('layui-btn-disabled');
                      $("#getCaptcha").html("重获验证码");
                    }else {
                      curCount --;
                      $("#getCaptcha").html(`${curCount}秒后重获`);
                    }
                  };
              
                  function countdown() {
                    $("#getCaptcha").addClass('layui-btn-disabled'); 
                    curCount = 60
                    InterValObj = window.setInterval(SetRemainTime, 1000);
                  }
                });
                layui.form.render(null, 'forget-form')
                layui.form.on('submit(forget-next-button2)', (data: any) => {
                  simpleLoading.show()
                  checkPassword({
                    verificationCode: data.field.verifyCode,
                    username: usernameLocal,
                  }).then(() => {
                    simpleLoading.hide()
                    verificationCode = data.field.verifyCode
                    layer.close(layerIndex2)
                    const layerIndex3 = layer.open({
                      type: 1,
                      title: '忘记密码',
                      area: ['700px', '340px'],
                      content: templateRender({
                        step: 3,
                      }),
                      success() {
                        layui.form.render(null, 'forget-form')
                        layui.form.on('submit(forget-next-button3)', (data: any) => {
                          const { password1, password2 } = data.field
                          if (password1 !== password2) {
                            eMsg('两次输出的密码不一致')
                          } else {
                            simpleLoading.show()
                            forgetPassword({
                              password: password1,
                              username: usernameLocal,
                              verificationCode
                            }).then(() => {
                              step === 4
                              layer.close(layerIndex3)
                              simpleLoading.hide()
                              layer.open({
                                type: 1,
                                title: '忘记密码',
                                area: ['700px', '340px'],
                                content: templateRender({
                                  step: 4,
                                }),
                                success() {
                                  layui.form.render(null, 'forget-form')
                                  layui.form.on('submit(forget-close-button)', (data: any) => {
                                    layer.closeAll()
                                  })
                                },
                                end: () => {
                                  layer.closeAll()
                                }
                              })
                            })
                          }
                        })
                      },
                      end: () => {
                        layer.close(layerIndex3)
                      }
                    })
                  }).catch((err: any) => {
                    eMsg(err.message)
                    simpleLoading.hide()
                  })
                })
              },
              end: () => {
                layer.close(layerIndex2)
              }
            })
          }).catch((err: any) => {
            eMsg(err.message)
            simpleLoading.hide()
          })
        })
      },
      end: () => {
        layer.close(layerIndex1)
      }
    });
  })
})
