import { getFullUrl } from '@/utils/url'
import { eMsg, simpleLoading } from '@/utils/interaction'
import {
  getLoginPublicKey,
  getValidateKey,
  getAllCourtList,
  simpleLogin,
  SimpleLoginData,
  loginWithCourtRole
} from '@/services/login'
import { userFinalLogin } from './roles'
import CourtItemPug from './court-item.pug'

function setCourtItem(list: any[]) {
  $('#form-item-court').html(CourtItemPug({ list })).show()
  layui.form.render(null, 'login-form')
}

export async function setCourtList() {
  setCourtItem([])
  try {
    const res = await getAllCourtList()
    const lastCourtId = localStorage.getItem('lastCourtId')
    const list = res.map((item) => {
      const courtId = item.id + ''
      return {
        value: courtId,
        text: item.courtName,
        selected: lastCourtId === courtId
      }
    })
    if (list.length === 1) {
      list[0].selected = true
    }
    $('#forget').css('display', 'none')
    setCourtItem(list)
  } catch (err: any) {
    eMsg(err.message)
  }
}

export function setValidateImg() {
  getValidateKey().then((res) => {
    $('#validateKey').val(res.loginTokenKey)
    $('#validateCodeImg').attr(
      'src',
      getFullUrl('v2/auth/get/validate/code/' + res.loginTokenKey)
    )
  })
}

async function passwordLogin(data: SimpleLoginData) {
  const { publicKey } = await getLoginPublicKey()
  // @ts-ignore
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)
  const userName = encrypt.encrypt(data.userName) as string
  const password = encrypt.encrypt(data.password) as string
  const loginData = { ...data, userName, password }
  return simpleLogin(loginData)
}

export async function handleLogin(isCourt: boolean, data: any) {
  simpleLoading.show()
  try {
    await passwordLogin(data)
    if (isCourt) {
      await loginWithCourtRole(data.courtId)
    }
    // IE 获取的路径不以斜杠开始
    const arr = /^\/?(court|org)\/login.html/.exec(location.pathname)
    if (arr) {
      const loginMode = arr[1] === 'court' ? 'court' : 'organization'
      localStorage.setItem('loginMode', loginMode)
    } else {
      localStorage.removeItem('loginMode')
    }
    simpleLoading.hide()
    if (isCourt) {
      localStorage.setItem('lastCourtId', data.courtId)
      window.location.assign('/home.html')
    } else {
      setValidateImg()
      // 打开选择角色的弹窗
      userFinalLogin()
    }
  } catch (err: any) {
    setValidateImg()
    simpleLoading.hide()
    eMsg(err.message)
  }
}
