import CryptoJS from 'crypto-js'
import { getFullUrl } from './url'
import { goToLoginPage } from './login'

export const isEncrypt = true;
export const showDecrypt = false;
export const KEYS = '5c9489a7d33ef68b'
/**
 * 加密
 * @param encryptString 要加密的字符串
 * @param key 秘钥
 * @returns {string} 加密后的字符串
 */
export function aesEncrypt(encryptString: any, key: string) {
  const newKey = CryptoJS.enc.Utf8.parse(key)
  const srcs = CryptoJS.enc.Utf8.parse(encryptString)
  const encrypted = CryptoJS.AES.encrypt(srcs, newKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted?.ciphertext?.toString(CryptoJS.enc.Base64)
  // return encrypted.toString();
}

/**
 * 解密
 * @param decryptString 要解密的字符串
 * @param key 秘钥
 * @returns {string} 解密后的字符串
 */
export function aesDecrypt(decryptString: any, key: string) {
  const newKey = CryptoJS.enc.Utf8.parse(key)
  const decrypt = CryptoJS.AES.decrypt(decryptString, newKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  //将解密对象转换成UTF8的字符串
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  //返回解密结果
  return decryptedStr.toString()
}

export async function get<T = any>(url: string) {
  const res = await new Promise<ApiResult<T>>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    url = getFullUrl(url)
    url = url + (url.includes('?') ? '&' : '?') + '_=' + Date.now()
    xhr.open('GET', url)
    const token = localStorage.getItem('token')
    if (token) {
      xhr.setRequestHeader('token', token)
    }
    // IE 不支持 json 类型
    xhr.responseType = 'json'
    // IE9+
    if ('onload' in XMLHttpRequest.prototype) {
      xhr.onload = function () {
        resolve(getResponseJson(xhr))
      }
      xhr.onerror = function () {
        reject(new Error(`Error ${xhr.status}: ${xhr.statusText}`))
      }
    } else {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          resolve(getResponseJson(xhr))
        }
      }
    }
    xhr.send()
  })
  return handleResponse(res, url)
}

export async function postData<T = any>(
  url: string,
  data?: Record<string, any> | null
) {
  const res = await new Promise<ApiResult<T>>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    url = getFullUrl(url)
    xhr.open('POST', url)
    const token = localStorage.getItem('token')
    if (token) {
      xhr.setRequestHeader('token', token)
    }
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    // IE 不支持 json 类型
    xhr.responseType = 'json'
    // IE9+
    if ('onload' in XMLHttpRequest.prototype) {
      xhr.onload = function () {
        resolve(getResponseJson(xhr))
      }
      xhr.onerror = function () {
        reject(new Error(`Error ${xhr.status}: ${xhr.statusText}`))
      }
    } else {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          resolve(getResponseJson(xhr))
        }
      }
    }
    xhr.send(data && (isEncrypt ? aesEncrypt(JSON.stringify(data), KEYS) : JSON.stringify(data)))
  })
  return handleResponse(res, url)
}

async function getResponseJson(xhr: XMLHttpRequest) {
  if (xhr.status !== 200) {
    throw new Error(`Error ${xhr.status}: ${xhr.statusText}`)
  }
  // IE8 不支持
  // IE9-11 不支持返回 json 类型，其是字符串
  const res =  xhr.response
  if (typeof res === 'object') {
    return isEncrypt ? JSON.parse(aesDecrypt(res.response, KEYS)) : res
  }
  const text = xhr.responseText
  if (text) {
    const newText = JSON.parse(text)
    return isEncrypt ? aesDecrypt(newText.response, KEYS) : newText
  }
  throw new Error('未返回数据')
}

function handleResponse<T>(res: ApiResult<T>, url: any) {
  const newRes: ApiResult<T> =  res !== undefined ? typeof res === 'object' ? res : JSON.parse(res) : undefined
  if (newRes === undefined || newRes.returnCode === undefined) {
    throw new Error('数据异常')
  }

  if (newRes.returnCode === '200017' || newRes.returnCode === '200005') {
    // 登录过期
    goToLoginPage()
  }

  if(showDecrypt) {
    console.log(url, newRes)
  }
  if (newRes.returnCode !== '100') {
    if(!url.includes('document/template/generate')) {
      layer.msg(newRes.returnMsg, { icon: 5 });
    }
    throw new Error(newRes.returnMsg)
  }
  return newRes.returnData
}

interface ApiResult<T> {
  returnCode: string
  returnData: T
  returnMsg: string
}
